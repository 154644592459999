.navbar-nav .nav-link {
    color: #fff;
}

.dropend .dropdown-toggle {
    color: rgb(0, 0, 0);
    margin-left: 1em;
}

.dropdown-item:hover {
    background-color: rgb(0, 0, 0);
    color: #fff;
}

.dropdown .dropdown-menu {
    display: none;
}

.dropdown:hover>.dropdown-menu,
.dropend:hover>.dropdown-menu {
    display: block;
    margin-top: 0;
    margin-left: 0;
}

@media screen and (min-width:992px) {
    .dropend:hover>.dropdown-menu {
        position: absolute;
        top: 0;
        left: 100%;
    }

    .dropend .dropdown-toggle {
        margin-left: .5em;
    }
}